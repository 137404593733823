import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loadPermissionData } from "services/appServices/permissionService";

const initialState = {
  data: {},
};

const permissionSlice = createSlice({
  name: "permision",
  initialState,
  reducers: {
    getPermissionList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getPermissionList } = permissionSlice.actions;
export default permissionSlice.reducer;
