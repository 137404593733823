import { apiRequest } from "utils/async/apiUtils";
import { message } from "antd";

export const loadProfileDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "profile");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const editProfile = async (data) => {
  try {
    const res = await apiRequest(
      "put",
      "profile",
      {
        body: {
          id: data.id,
          // "fullname[firstName]": data.firstName,
          // "fullname[lastName]": data.lastName,
          // email: data.email,
          // image: data.fileList,
          name: data?.name,
          email: data?.email,
          password: data?.password || null,
          image: data?.fileList,
          phone: data?.phone,
          phoneCode: data?.phoneCode,
          // dateOfBirth: data?.dateOfBirth,
          dateOfBirth: new Date(data?.dateOfBirth).toLocaleDateString("en-CA"),
          "detailsOfPan[nameInPan]": data?.nameInPan,
          "detailsOfPan[panNumber]": data?.pan,
          image: data?.fileList,
          "address[city]": data?.city,
          "address[state]": data?.state,
          "address[address1]": data?.address1,
          "address[address2]": data?.address2,
          "address[zipcode]": data?.zipcode,
          "address[country]": data?.country,
        },
      },
      "multipart/form-data"
    );
    message.success(res.data.message);
    return res;
  } catch (error) {}
};
