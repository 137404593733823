import React, { useEffect, useState } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadSettingDetails } from "services/appServices/siteSettingService";
import { getSettings } from "store/slices/settingsSlice";
import { setProfileDetails } from "store/slices/profileSlice";
import { loadProfileDetails } from "services/appServices/editProfileService";
// import { loadPermissionDetails } from "services/appServices/permissionService";
import { getPermissionList } from "store/slices/permissionSlice";
import { loadPermissionData } from "services/appServices/permissionService";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state?.settings?.data);
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [permissionLoaded, setpermissionLoaded] = useState(true);
  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  // const profile = useSelector((state) => state?.profile?.pofileData);
  useEffect(() => {
    loadProfileDetails().then((res) => {
      dispatch(setProfileDetails(res?.data?.data));
      // console.log(res?.data?.data, "ssssssss");
      // setProfileData(res?.data?.data);
    });
  }, []);

  // const userId = profileData?._id;

  // console.log(userId, "profileee");
  // useEffect(() => {
  //   loadPermissionDetails(userId, setProfileData, setAdminDataLoaded).then(
  //     (res) => {
  //       if (res.status === 200) {
  //         console.log(res, "resss");
  //         dispatch(getPermissionList(res.data));
  //       }
  //     }
  //   );
  // }, [userId]);

  // useEffect(() => {
  //   loadPermissionData({ keyword }, setpermissionLoaded).then((res) => {
  //     setList(res.docs);
  //     // console.log(res.docs, "kjhghjkjh");
  //     dispatch(getPermissionList(res?.docs));
  //     // if (res.status === 400) {
  //     //   message.error(res.message);
  //     // }
  //   });
  // }, [keyword]);

  useEffect(() => {
    loadSettingDetails().then((res) => {
      if (res.status === 200) {
        dispatch(getSettings(res?.data));
      }
    });
  }, []);

  function favIconChange() {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = settings?.favicon?.url;
  }
  favIconChange();

  return <Component {...props} />;
};

export default AppRoute;
