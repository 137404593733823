import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import topic from "./slices/topicSlice";
import lang from "./slices/langSlice";
import profile from "./slices/profileSlice";
import settings from './slices/settingsSlice'
import permission from "./slices/permissionSlice";
import loggedUser from './slices/currentLoggedUserSlice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    lang,
    topic,
    profile,
    settings,
    loggedUser,
    permission,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
