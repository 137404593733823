import { apiRequest } from "utils/async/apiUtils";
import { message } from "antd";

export const loadSettingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "site-settings");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return data
  } catch (err) {
    setLoaded(true);
    return err
  }
};

export const editSettings = async (data) => {
  try {
    const res = await apiRequest(
      "put",
      "site-settings",
      {
        body: {
          id: data.id,
          title: data.title,
          description: data.description,
          email: data.email,
          phone: data.phone,
          "address[address1]": data.address,
          logo: data.fileList,
          favicon: data.favIcon,
        },
      },
      "multipart/form-data"
    );
    return res
  } catch (error) {
    return error
  }
};


export const editSettingsApplink = async (id, params) => {
  try {
    // console.log(id, params, "params");
    const res = await apiRequest(
      "put",
      "site-settings",
      {
        body: {
          id,
          ...params,
        },
      },
      "multipart/form-data"
    );

    const data = res.data;

    return res;
  } catch (err) {
    throw new Error(err);
  }
};
