import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/apps/dashboard/")),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "error-page-1",
    path: `${APP_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  // admin=========>
  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/admin/AdminList")
    ),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/add`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.profile",
    path: `${APP_PREFIX_PATH}/admin/admin-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/AdminProfile")
    ),
  },

  // user=======>

  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/pages/user/userList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/users/add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },
  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/user/UserDetails")
    ),
  },

  //Fundraiser ===========>
  {
    key: "fundraiser.list",
    path: `${APP_PREFIX_PATH}/fundraiser/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/fundraiser/FundraiserList")
    ),
  },
  {
    key: "fundraiser.list.add",
    path: `${APP_PREFIX_PATH}/fundraiser/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/fundraiser/AddFundraiser")
    ),
  },
  {
    key: "fundraiser.list.edit",
    path: `${APP_PREFIX_PATH}/fundraiser/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/fundraiser/AddFundraiser")
    ),
  },
  {
    key: "fundraiser.list.details",
    path: `${APP_PREFIX_PATH}/fundraiser/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/fundraiser/DetailsFundraiser")
    ),
  },
  {
    key: "transaction.list",
    path: `${APP_PREFIX_PATH}/transaction/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/transaction/Transaction")
    ),
  },
  {
    key: "transaction.details",
    path: `${APP_PREFIX_PATH}/transaction/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/transaction/TransactionDetails")
    ),
  },
  {
    key: "supporters.list",
    path: `${APP_PREFIX_PATH}/supporters/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/supporters/Supporters")
    ),
  },
  {
    key: "bankDetails.list",
    path: `${APP_PREFIX_PATH}/bankDetails/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/bankDetails/BankDetails")
    ),
  },

  // topics=======>

  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/media/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/media/MediaList")
    ),
  },
  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

  {
    key: "cms.emails.list",
    path: `${APP_PREFIX_PATH}/email-templates/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/email/emailList")
    ),
  },
  {
    key: "cms.emails.edit",
    path: `${APP_PREFIX_PATH}/email-templates/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },
  {
    key: "cms.emails.add",
    path: `${APP_PREFIX_PATH}/email-templates/add`,
    component: React.lazy(() => import("views/app-views/pages/email/EmailAdd")),
  },

  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/pages/page/pageList")),
  },
  {
    key: "cms.pages.edit",
    path: `${APP_PREFIX_PATH}/pages/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
  {
    key: "cms.pages.add",
    path: `${APP_PREFIX_PATH}/pages/add`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },

  //Topic Section

  {
    key: "topics.list",
    path: `${APP_PREFIX_PATH}/topic/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/topics/TopicList")
    ),
  },
  {
    key: "topics.add",
    path: `${APP_PREFIX_PATH}/topic/add`,
    component: React.lazy(() => import("views/app-views/pages/topics/AddTopic")),
  },
  {
    key: "topics.edit",
    path: `${APP_PREFIX_PATH}/topic/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/topics/AddTopic")),
  },


  //Testimonials
  {
    key: "testimonials.list",
    path: `${APP_PREFIX_PATH}/testimonials/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/testimonials/TestimonialsList")
    ),
  },
  {
    key: "testimonials.add",
    path: `${APP_PREFIX_PATH}/testimonials/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/testimonials/AddTestimonials")
    ),
  },
  {
    key: "testimonials.edit",
    path: `${APP_PREFIX_PATH}/testimonials/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/testimonials/AddTestimonials")
    ),
  },

  //Teams

  {
    key: "teams.list",
    path: `${APP_PREFIX_PATH}/teams/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/teams/TeamsList")
    ),
  },
  {
    key: "teams.add",
    path: `${APP_PREFIX_PATH}/teams/add`,
    component: React.lazy(() => import("views/app-views/pages/teams/AddTeams")),
  },
  {
    key: "teams.edit",
    path: `${APP_PREFIX_PATH}/teams/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/teams/AddTeams")),
  },

  // Blog

  {
    key: "blog.list",
    path: `${APP_PREFIX_PATH}/blog/list`,
    component: React.lazy(() => import("views/app-views/pages/blog/BlogList")),
  },
  {
    key: "blog.add",
    path: `${APP_PREFIX_PATH}/blog/add`,
    component: React.lazy(() => import("views/app-views/pages/blog/AddBlog")),
  },
  {
    key: "blog.edit",
    path: `${APP_PREFIX_PATH}/blog/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/blog/AddBlog")),
  },

  //Permissions
  {
    key: "permission.list",
    path: `${APP_PREFIX_PATH}/permissions/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/permissions/PermissionList")
    ),
  },

  //Role
  {
    key: "role.list",
    path: `${APP_PREFIX_PATH}/role/list`,
    component: React.lazy(() => import("views/app-views/pages/role/RoleList")),
  },

  //Stories

  {
    key: "stories.list",
    path: `${APP_PREFIX_PATH}/stories/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/stories/StoriesList")
    ),
  },
  {
    key: "stories.add",
    path: `${APP_PREFIX_PATH}/stories/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/stories/AddStories")
    ),
  },
  {
    key: "stories.edit",
    path: `${APP_PREFIX_PATH}/stories/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/stories/AddStories")
    ),
  },

  // News

  {
    key: "news.list",
    path: `${APP_PREFIX_PATH}/news/list`,
    component: React.lazy(() => import("views/app-views/pages/news/NewsList")),
  },
  {
    key: "news.add",
    path: `${APP_PREFIX_PATH}/news/add`,
    component: React.lazy(() => import("views/app-views/pages/news/AddNews")),
  },
  {
    key: "news.edit",
    path: `${APP_PREFIX_PATH}/news/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/news/AddNews")),
  },

  //JOBS Category

  {
    key: "jobs.category.list",
    path: `${APP_PREFIX_PATH}/jobs/category/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/jobsCategory/CategoryList")
    ),
  },
  // {
  //   key:"jobs.category.add",
  //   path:`${APP_PREFIX_PATH}/jobs/category/add`,
  //   component:React.lazy(()=>import("views/app-views/pages/jobs/jobsCategory/AddCategory")),
  // },
  // {
  //   key:"jobs.category.edit",
  //   path:`${APP_PREFIX_PATH}/jobs/category/edit/:id`,
  //   component:React.lazy(()=>import("views/app-views/pages/jobs/jobsCategory/AddCategory")),
  // },

  //JOBS Type

  {
    key: "jobs.type.list",
    path: `${APP_PREFIX_PATH}/jobs/type/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/jobTypes/TypeList")
    ),
  },

  //JOB SCHEDULE
  {
    key: "jobs.schedule.list",
    path: `${APP_PREFIX_PATH}/jobs/schedule/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/schedule/ScheduleList")
    ),
  },

  //JOB LIST
  {
    key: "jobs.job-list",
    path: `${APP_PREFIX_PATH}/jobs/job-list`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/jobList/JobList")
    ),
  },
  {
    key: "jobs.job-list.add",
    path: `${APP_PREFIX_PATH}/jobs/job-list/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/jobList/AddJobList")
    ),
  },
  {
    key: "jobs.job-list.edit",
    path: `${APP_PREFIX_PATH}/jobs/job-list/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/jobList/AddJobList")
    ),
  },

  {
    key: "jobs.details",
    path: `${APP_PREFIX_PATH}/jobs/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/jobDetails/JobDetails")
    ),
  },

  //JOB Application List
  {
    key: "jobs.application-list",
    path: `${APP_PREFIX_PATH}/job-application/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/jobs/application/JobApplication")
    ),
  },

  //Category Relation
  {
    key: "relation",
    path: `${APP_PREFIX_PATH}/category/relation/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/category/relation/RelationList")
    ),
  },
  //Category Hospital
  {
    key: "hospital",
    path: `${APP_PREFIX_PATH}/category/hospital/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/category/hospital/HospitalList")
    ),
  },
  //Category Medical
  {
    key: "medical",
    path: `${APP_PREFIX_PATH}/category/medical/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/category/medical/MedicalList")
    ),
  },
  // Category Cause
  {
    key: "cause",
    path: `${APP_PREFIX_PATH}/category/cause/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/category/cause/CauseList")
    ),
  },

  //enquiry

  {
    key: "enquiry.list",
    path: `${APP_PREFIX_PATH}/enquiry/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/enquiry/EnquiryList")
    ),
  },

  //FAQ
  {
    key: "faq.list",
    path: `${APP_PREFIX_PATH}/faq/list`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqList")),
  },
];
